<!-- 매체 리스트 -->
<template>
  <v-container
    id="mediaList"
    fluid
    tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
        <base-material-card
                color="green">
          <template v-slot:heading>
            <h1 class="display-2 font-weight-regular">
              매체 리스트
            </h1>
          </template>
          <v-card-text>
            <v-row justify="space-between" align="center">
              <v-col cols="2">
                <v-select
                  :items="config.contractStatusOptions"
                  v-model="vModel.contractStatusId"
                  label="제휴 상태"
                  color="secondary"
                  @change="submitKeyword"/>
              </v-col>
              <v-col cols="2">
                <form @submit.prevent="submitKeyword">
                  <v-text-field
                        v-model="keyword"
                        append-icon="mdi-magnify"
                        class="ml-auto pt-0"
                        label="매체명 검색"
                        name="keyword"
                        color="secondary"
                        hide-details
                        single-line />
                </form>
              </v-col>
            </v-row>

            <v-divider class="mt-3" />
            <template v-if="resultMedia">
              <v-data-table
                      :headers="config.headers"
                      :items="resultMedia.media"
                      :items-per-page="Number(resultMedia.meta.perPage)"
                      item-key="key"
                      no-data-text="매체가 없습니다."
                      hide-default-footer>
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="text-center">{{resultMedia.meta.total - index}}</td>
                    <td class="text-center"><a v-on:click="goEdit(item)" title=": 매체 수정">{{ toContractText(item.contract_status_id) }}</a></td>
                    <td class="text-center"><a v-on:click="goInventory(item)" title=": 인벤토리 관리">{{ item.kor_name }}</a></td>
                    <td class="text-center">{{ item.name }}</td>
                    <td class="text-center">
                      <template v-if="item.user">
                        {{item.user.name}}
                      </template>
                      <template v-else>-</template>
                    </td>
                    <td class="text-center">
                      <template v-if="item.user">
                        {{item.user.email}}
                      </template>
                      <template v-else>-</template>
                    </td>
                    <td class="text-center">
                      <v-btn @click="goEdit(item)" small rounded color="warning">수정</v-btn>
                    </td>
                    <td class="text-center">
                      <v-btn @click="goInventory(item)" small rounded color="success">{{item.inventory_count}}</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-pagination
                      v-model="vModel.page"
                      :length="resultMedia.meta.lastPage"
                      :total-visible="10"
                      @input="paginationClick"
                      circle
                      class="justify-center mt-5"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"/>
            </template>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
            v-model="vModel.snackbar"
            :type="vModel.snackbarType"
            v-bind="{
          'top': true,
          'right': true
        }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        list: null
      },
      keyword: null,
      vModel: {
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
        page: 1,
        contractStatusId: 3,
      },
      config: {
        orderbyOptions: [
          { value: 'created', text: '누적 매출' },
          { value: 'name', text: '월평균 매출' },
          { value: 'commission', text: '평균 이익율' },
          { value: 'page_view', text: '월평균 페이지 뷰' },
          { value: 'ctr', text: 'CTR' },
          { value: 'rpm', text: 'RPM' }
        ],
        contractStatusOptions: [
          { value: 'all', text: '전체' },
          { value: 3, text: '제휴 중' },
          { value: 2, text: '준비 중' },
          { value: 1, text: '등록' },
          { value: 4, text: '일시 정지' },
          { value: 6, text: '보류' },
          { value: 5, text: '중단' },
          { value: 7, text: '보관' }
        ],
        headers: [
          { text: 'No', value: 'no', align: 'center' },
          { text: '제휴 상태', value: 'contract_status_id', align: 'center' },
          { text: '매체명', value: 'kor_name', align: 'center' },
          { text: '호스트명', value: 'name', align: 'center' },
          { text: '이름', value: 'user.name', align: 'center' },
          { text: '계정 이메일', value: 'user.email', align: 'center' },
          { text: '정보 수정', value: 'edit', align: 'center' },
          { text: '인벤토리 관리', value: 'inventory', align: 'center' },
        ]
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 페이지 로드시 */
    refresh () {
      let send = {}
      if (this.$route.query.page) {
        send.page = this.$route.query.page
        this.vModel.page = Number(this.$route.query.page)
      }
      if (this.$route.query.status) {
        send.status = this.$route.query.status
        this.vModel.contractStatusId = (this.$route.query.status === 'all') ? 'all' : Number(this.$route.query.status)
      }
      if (this.$route.query.keyword) {
        send.keyword = this.$route.query.keyword
        this.keyword = this.$route.query.keyword
      }
      this.$router.replace({ name: 'mediaList', query: send })
      this.mediaList()
    },
    /* 매체 리스트 페이지네이션 클릭 */
    paginationClick (page) {
      this.vModel.page = page
      let send = {
        page: page
      }
      if (this.$route.query.status) {
        send.status = this.$route.query.status
      }
      if (this.$route.query.keyword) {
        send.keyword = this.$route.query.keyword
      }
      this.$router.replace({ name: 'mediaList', query: send })
      this.mediaList()
    },
    /* 검색시 */
    submitKeyword () {
      let send = {}
      if (this.vModel.contractStatusId) {
        send.status = this.vModel.contractStatusId
      }
      if (this.keyword) {
        send.keyword = this.keyword
      }
      this.$router.push({ name: 'mediaList', query: send })
      this.vModel.page = 1
      this.mediaList()
    },
    /* 정보 수정 클릭시 */
    goEdit (v) {
      this.$router.push({ name: 'mediaEdit', params: { media_id: v.key } })
    },
    /* 인벤토리 관리 클릭시 */
    goInventory (v) {
      this.$router.push({ name: 'mediaInventory', params: { media_id: v.key } })
    },
    /* 매체 리스트 가져오기 */
    mediaList () {
      let send = {
        per: 20,
        page: 1,
        orderby: 'created',
        direction: 'desc',
        contract_status_id: this.vModel.contractStatusId
      }
      if (this.vModel.contractStatusId === 'all') {
        send.contract_status_id = null
      }
      if (this.$route.query.page) {
        send.page = this.$route.query.page
      }
      if (this.$route.query.status) {
        send.status = this.$route.query.status
      }
      if (this.$route.query.keyword) {
        send.keyword = this.$route.query.keyword
        send.where = 'kor_name'
      }
      this.xhttp({
        url: '/media',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.list = response.data.data
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    toContractText (contractStatusId) {
      if (contractStatusId) {
        for (let item of this.config.contractStatusOptions) {
          if (item.value === contractStatusId) {
            return item.text
          }
        }
      }
      return ''
    }
  },
  computed: {
    resultMedia () {
      if (this.response.list) {
        return this.response.list
      } else {
        return false
      }
    }
  },
  watch: {
    keyword: function (val) {
      if (val === '') {
        this.submitKeyword()
      }
    }
  }
}
</script>
